<template>
  <Header title="Friends" subtitle="Invited friends in your trip"></Header>
</template>

<script>
import stringMixin from "@/mixins/string.mixin";

export default {
  name: "Account",
  mixins: [stringMixin],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>

</style>
